import React from 'react'

import image_1 from '../../images/choose_1.png'
import image_2 from '../../images/choose_2.png'
import image_3 from '../../images/choose_3.png'
import image_4 from '../../images/choose_4.png'
import image_5 from '../../images/choose_5.png'
import image_6 from '../../images/choose_6.png'
import image_7 from '../../images/choose_7.png'
import image_8 from '../../images/choose_8.png'

const tiles = [
  {
    title: 'Daily care experts',
    image: image_1,
    border: '#714AA3',
    background: '#F4F1F8',
    text: 'We specialize in around the clock care to help seniors live well at home.',
  },
  {
    title: 'Customer service',
    image: image_2,
    border: '#D16BD3',
    background: '#FCF4FC',
    text: 'Our great customer service team is available to help you anytime.',
  },
  {
    title: 'Balanced care',
    image: image_3,
    border: '#E76675',
    background: '#FDF3F4',
    text: 'Our unique approach to care promotes healthy mind, body and spirit.',
  },
  {
    title: 'No long term contract',
    image: image_4,
    border: '#2E7D61',
    background: '#EFF5F3',
    text: 'Use our services only as long as you’re 100% satisfied.',
  },
  {
    title: 'Peace of mind',
    image: image_5,
    border: '#26758D',
    background: '#EEF4F6',
    text: 'English, Spanish and Creole speaking staff and caregivers available.',
  },
  {
    title: 'A trusted partner',
    image: image_6,
    border: '#0057A8',
    background: '#EBF2F8',
    text: 'Rockaway HC is the trusted non-profit referral choice for elder care professionals.',
  },
  {
    title: 'Insurance accepted',
    image: image_7,
    border: '#00AEEF',
    background: '#EDFAFF',
    text: 'We accept most insurances. Call for more details',
  },
  {
    title: 'High caliber caregivers',
    image: image_8,
    border: '#D4C577',
    background: '#FBF9F5',
    text: 'We typically hire only 1 in 25 applicants and provide ongoing training.',
  },
]

const Tile = ({ title, image, border, background, text }) => {
  return (
    <div
      className="p-8 border rounded-xl w-4/5 flex-shrink-0 mx-2 max-w-xs md:my-2"
      style={{ backgroundColor: background, borderColor: border }}>
      <img src={image} alt={title} className="w-14 mb-6" />
      <h3 className="font-semibold text-2xl mb-4">{title}</h3>
      {text}
    </div>
  )
}

const ReasonsScroll = () => {
  return (
    <div className="flex flex-row overflow-x-auto md:flex-wrap md:justify-center md:px-10">
      {tiles.map((t, i) => (
        <Tile key={i} {...t} />
      ))}
    </div>
  )
}

const WhyChooseSection = () => {
  return (
    <div className="pb-32 pt-16">
      <h2 className="text-4xl text-center font-semibold mb-10 px-4 sm:px-10">
        Why Choose Rockaway Home Care?
      </h2>
      <p className="text-lg text-center mb-10  px-4 sm:px-10">
        We are committed to being your shoulder to lean on and providing honest
        advice for your loved one’s situation during this delicate time.
      </p>
      <ReasonsScroll />
    </div>
  )
}

export default WhyChooseSection
