import * as React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'

import BulletSection from '../components/index/BulletSection'
import Hero from '../components/index/Hero'

import InfoSection from '../components/index/InfoSection'
import WhyChooseSection from '../components/index/WhyChooseSection'
import GoogleReviewsBadge from '../components/index/GoogleReviewsBadge'
import BottomCTASection from '../components/index/BottomCTASection'
import ReviewsSection from '../components/index/ReviewsSection'
import TabsSection from '../components/index/TabsSection'

const HtmlHead = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        property="og:image"
        content="https://enroll.rhc.care/wp-content/uploads/2021/06/rockaway-reviews.jpg"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="CDPAP - (Consumer Directed Personal Assistance Program) Get Paid To Care For Your Loved One"
      />
      <meta property="og:url" content="https://enroll.rhc.care/cdpap/" />
      <meta property="og:site_name" content="Rockaway Home Care" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="CDPAP - (Consumer Directed Personal Assistance Program) Get Paid To Care For Your Loved One"
      />
      <meta
        name="twitter:image"
        content="https://enroll.rhc.care/wp-content/uploads/2021/06/rockaway-reviews.jpg"
      />
      <link
        rel="icon"
        href="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        href="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-180x180.png"
      />
      <meta
        name="msapplication-TileImage"
        content="https://enroll.rhc.care/wp-content/uploads/2021/05/cropped-favi0-270x270.png"
      />
      <title>
        CDPAP - (Consumer Directed Personal Assistance Program) Get Paid To Care
        For Your Loved One
      </title>
    </Helmet>
  )
}

const YoutubeEmbed = () => {
  return (
    <div className="w-4/5 mx-auto max-w-3xl py-16">
      <div className="relative pb-[50%] pt-6 h-0">
        <iframe
          className="w-full h-full absolute top-0 left-0"
          src="https://www.youtube-nocookie.com/embed/Ho_G4kikkB8?controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      </div>
    </div>
  )
}

// markup
const IndexPage = () => {
  return (
    <>
      <HtmlHead />
      <Header phoneNumber={"(516) 403-8211"} phoneCta="Call Us Today on" ctaText="Need help right now?" />
      <main>
        <div className="bg-gradient-to-b from-[#EDFAFF] to-transparent px-4 pt-8 pb-20 lg:px-16 lg:pt-12 ">
          <Hero />
        </div>
        <div className="flex justify-center my-8 lg:hidden">
          <GoogleReviewsBadge />
        </div>
        <BulletSection />
        <div className="bg-gradient-to-b from-transparent to-[#F4F1F8]">
          <InfoSection />
          <YoutubeEmbed />
        </div>
        <div className="bg-[#F4F1F8]">
          <TabsSection />
        </div>
        <div className="bg-gradient-to-b from-[#F4F1F8] via-transparent to-[#EBF2F8]">
          <WhyChooseSection />
        </div>
        <div className="bg-[#EBF2F8]">
          <ReviewsSection />
        </div>
        <div className="bg-gradient-to-b from-[#EBF2F8] to-transparent">
          <BottomCTASection />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
