import React from 'react'
import ProgramScroll from './CommonPrograms'

const steps = [
  'We start off with an Introductory Call, our team is ready to answer any of your enrollment questions',
  'In-Home Visits are scheduled so we can help you complete your enrollment paperwork',
  'Medicaid & Insurance Evaluations are scheduled with the person receiving care. We assist by being available before, during and after the evaluation to answer any questions',
  'Authorizations can be a waiting game, our team stays in touch with Medicaid and insurance representatives so you don’t have to',
]

const Step = ({ number, text }) => {
  return (
    <div className="flex flex-row gap-4">
      <div className="w-8 h-8 bg-brand-500 text-white rounded-full text-center flex-shrink-0 leading-8 font-bold">
        {number}
      </div>
      <p className="text-2xl leading-9">{text}</p>
    </div>
  )
}

const EnrollmentTab = () => {
  return (
    <>
      <h2 className="text-4xl leading-tight text-center font-semibold px-4 md:px-14 mb-14 max-w-7xl mx-auto">
        Rockaway Home Care stays with you every step of the way as you seek
        authorization. Here is what you can expect:
      </h2>
      <div className="flex flex-col gap-16 px-4 md:grid md:grid-cols-2 md:px-14 max-w-7xl mx-auto">
        {steps.map((step, i) => (
          <Step number={i + 1} key={i} text={step} />
        ))}
      </div>
      <div className="mt-6">
        <ProgramScroll />
      </div>
    </>
  )
}

export default EnrollmentTab
