import React, { useRef } from 'react'
import classnames from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from '../Button'
import parser from 'ua-parser-js'
import Modal, { ModalHandlers } from '../Portal'

import success_icon from '../../images/success.svg'
import failure_icon from '../../images/failure.svg'
import { useState } from 'react'

const inputClass = classnames(
  'flex-grow min-w-0 w-full border-2 border-brand-500 rounded-lg h-12 px-2'
)

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const EligibilitySchema = Yup.object().shape({
  name: Yup.string().max(50, 'Too Long!').required('Required'),
  last_name: Yup.string().max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Email is not valid').required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
})

const StyledError = ({ children }) => (
  <div className="text-red-400 mt-1 text-sm">{children}</div>
)

const deviceData = () => {
  const { browser, device, os } = parser(navigator.userAgent)
  return {
    user_agent: navigator.userAgent,
    browser: browser.name,
    device: device.model,
    device_type: device.type,
    device_vendor: device.vendor,
    os: os.name,
  }
}

const adwordsData = () => {
  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  return {
    source_url: window.location.href,
    ...params,
  }
}

const submitRequest = (values) => {
  const postURL =
    process.env.NODE_ENV === 'production'
      ? 'https://enroll-rhc-worker.rockaway-email.workers.dev/cdpap-enroll'
      : 'http://localhost:8787/cdpap-enroll'

  return fetch(postURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
}

const modal_content = {
  success: {
    icon: success_icon,
    title: 'Success!',
    message: 'Your details have been submitted.',
    btn: 'Close',
  },
  failure: {
    icon: failure_icon,
    title: 'Uh oh!',
    message: 'Looks like something went wrong.',
    btn: 'Close',
  },
}

const FeedbackModal = ({ status, action }) => {
  const content = modal_content[status]

  return (
    <div className="flex flex-col gap-6 items-center">
      <img width={72} height={72} src={content.icon} />
      <div>
        <h4
          className={classnames(
            'text-3xl text-center',
            status === 'success' ? 'text-brand-500' : 'text-carmine-500'
          )}>
          {content.title}
        </h4>
        <h5
          className={classnames(
            'text-2xl text-center',
            status === 'success' ? 'text-brand-500' : 'text-carmine-500'
          )}>
          {content.message}
        </h5>
      </div>
      <Button full onClick={action}>
        {content.btn}
      </Button>
    </div>
  )
}

const EligibilityForm = () => {
  const modal = useRef<ModalHandlers>()
  const [status, setStatus] = useState<null | 'success' | 'failure'>(null)

  const showMessage = (status) => {
    setStatus(status)
    modal.current?.openModal()
  }

  return (
    <>
      <Modal ref={modal}>
        <FeedbackModal
          status={status}
          action={() => modal.current?.closeModal()}
        />
      </Modal>
      <Formik
        initialValues={{
          name: '',
          last_name: '',
          email: '',
          phone: '',
          help: '',
          has_medicaid: 'yes',
          medicaid_help: false,
        }}
        validateOnChange={false}
        validationSchema={EligibilitySchema}
        onSubmit={(
          { medicaid_help, ...values },
          { setSubmitting, resetForm }
        ) => {
          const data = {
            ...adwordsData(),
            ...deviceData(),
            ...values,
          }
          submitRequest(data)
            .then(() => {
              if (window?.gtag) {
                window.gtag('event', 'conversion', {
                  send_to: 'AW-10804368874/iO0QCLjmpYIDEOqr9p8o',
                })
                window.gtag('event', 'Submit', {
                  'event_category': 'lead',
                  'event_label': 'Form',
                  'value': 1
                });
              }
              if (window?.fbq) {
                window.fbq('track', 'Lead')
              }
              resetForm()
              showMessage('success')
            })
            .catch((e) => {
              console.error(e)
              showMessage('failure')
            })
            .finally(() => {
              setSubmitting(false)
            })
        }}>
        {({ isSubmitting, values }) => (
          <Form id="eligibilityForm" className="space-y-4" autoComplete="on">
            <h3 className="font-bold text-2xl">Find out if you're eligible</h3>
            <div>
              <label
                htmlFor="field_name"
                className="block font-bold text-sm py-2">
                Name
              </label>
              <div className="flex flex-col xs:flex-row gap-4">
                <div className="flex-grow">
                  <Field
                    id="field_name"
                    name="name"
                    autoComplete="given-name"
                    placeholder="First Name"
                    className={inputClass}
                  />
                  <ErrorMessage component={StyledError} name="name" />
                </div>
                <div className="flex-grow">
                  <Field
                    name="last_name"
                    autoComplete="family-name"
                    placeholder="Last Name"
                    className={inputClass}
                  />
                  <ErrorMessage component={StyledError} name="last_name" />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="field_email"
                className="block font-bold text-sm py-2">
                Email
              </label>
              <div className="flex flex-row">
                <Field
                  id="field_email"
                  type="email"
                  autoComplete="email"
                  name="email"
                  placeholder="Email"
                  className={inputClass}
                />
              </div>
              <ErrorMessage component={StyledError} name="email" />
            </div>
            <div>
              <label
                htmlFor="field_phone"
                className="block font-bold text-sm py-2">
                Phone number
              </label>
              <div className="flex flex-row">
                <Field
                  id="field_phone"
                  type="phone"
                  autoComplete="tel"
                  name="phone"
                  placeholder="Enter phone number"
                  className={inputClass}
                />
              </div>
              <ErrorMessage component={StyledError} name="phone" />
            </div>
            <div>
              <label
                htmlFor="field_help"
                className="block font-bold text-sm py-2">
                How can we help?
              </label>
              <div className="flex flex-row">
                <Field
                  as="textarea"
                  id="field_help"
                  className={`${inputClass} h-36`}
                  name="help"
                  placeholder="Tell us what sort of help you need"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-2 xs:flex-row">
                <div className="flex items-center gap-2 flex-grow justify-end text-right">
                  <label
                    className="font-bold text-sm"
                    htmlFor="field_has_medicaid">
                    I have medicaid
                  </label>
                  <Field
                    type="radio"
                    id="field_has_medicaid"
                    name="has_medicaid"
                    value="yes"
                  />
                </div>
                <div className="flex items-center gap-2 flex-grow justify-end text-right">
                  <label
                    className="font-bold text-sm"
                    htmlFor="field_no_medicaid">
                    I don't have medicaid
                  </label>
                  <Field
                    type="radio"
                    id="field_no_medicaid"
                    name="has_medicaid"
                    value="no"
                  />
                </div>
              </div>
              <ErrorMessage component={StyledError} name="has_medicaid" />
            </div>
            {values.has_medicaid === 'no' && (
              <>
                <div className="border-dashed border-t border-brand-500 w-full my-4" />
                <div className="flex justify-end items-center font-bold text-sm text-right">
                  <label htmlFor="help_checkbox">
                    Would you like help applying or determining eligibility?
                  </label>
                  <Field
                    className="w-6 h-6 ml-4 form-checkbox"
                    id="help_checkbox"
                    name="medicaid_help"
                    type="checkbox"
                  />
                </div>
              </>
            )}
            <div className="text-right !mt-6">
              <Button
                type="submit"
                disabled={
                  isSubmitting ||
                  (!values.medicaid_help && values.has_medicaid === 'no')
                }>
                Check eligibility now
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EligibilityForm
