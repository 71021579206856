import React, { useState } from 'react'
import FirstTab from './ProgramsTab'
import classnames from 'classnames'
import EnrollmentTab from './EnrollmentTab'
import StepsTab from './StepsTab'

const Tab = ({ children, active = false, onClick }) => {
  const classN = classnames(
    'flex-grow font-header rounded-lg px-4 py-3 text-center',
    active && 'bg-white text-brand-500'
  )
  return (
    <button onClick={onClick} className={classN}>
      {children}
    </button>
  )
}

const TabSelector = ({ selected, select }) => {
  const tabs = [
    'Apply for CDPAP',
    'The Enrollment Process',
    'How to Get Started',
  ]

  return (
    <div className="flex flex-col gap-1 p-1 bg-brand-500 text-white rounded-xl max-w-5xl md:flex-row justify-center md:mx-auto">
      {tabs.map((tab, i) => (
        <Tab key={i} active={i === selected} onClick={() => select(i)}>
          {tab}
        </Tab>
      ))}
    </div>
  )
}

const TabsSection = () => {
  const [selectedTab, selectTab] = useState(0)

  return (
    <div className="pt-14 pb-28">
      <div className="px-4 md:px-14 mb-10 md:mb-14">
        <TabSelector selected={selectedTab} select={selectTab} />
      </div>
      <div className={selectedTab === 0 ? '' : 'hidden'}>
        <FirstTab />
      </div>
      <div className={selectedTab === 1 ? '' : 'hidden'}>
        <EnrollmentTab />
      </div>
      <div className={selectedTab === 2 ? '' : 'hidden'}>
        <StepsTab />
      </div>
    </div>
  )
}

export default TabsSection
