import React from 'react'

import info_1 from '../../images/info_1.png'
import info_2 from '../../images/info_2.png'
import info_3 from '../../images/info_3.png'

const infos = [
  {
    title: 'What is CDPAP?',
    color: 'bg-lavender',
    image: info_1,
    text: 'Consumer Directed Personal Assistance Program (CDPAP) – a NYS Medicaid funded program allows seniors & people with disabilities to select, train and direct their own caregivers, including family members and friends. Caregivers enrolled in the CDPAP program are not required to be certified or licensed. CDPAP providers do not confront the limitations that many home health aides do, such as, restrictions on administering medications, or insulin injections.',
  },
  {
    title: 'Your choice',
    color: 'bg-eucalyptus',
    image: info_2,
    text: 'CDPAP allows family members, with the exception of spouses, or friends to become paid caregivers. Caregivers are not required to have certification or licensing and can administer skilled services needed, such as injections. You may switch your caregiver at any time if you are not completely satisfied with the care he/she is providing. You decide who cares for you and with the hours that suit your needs.',
  },
  {
    title: 'Flexible schedule',
    color: 'bg-teal',
    image: info_3,
    text: 'CDPAP allows you to create a schedule that best suits your needs and lifestyle. You can allocate weekly hours on a flexible day-to-day basis, rather than following a daily schedule of hours set by a third party agency. The program ultimately provides consumers with controls that many take for granted.',
  },
]

const Info = ({ title, image, text, color }) => {
  return (
    <div className="group flex flex-col relative items-center mb-20 sm:px-10 lg:mb-32 last:lg:mb-16 lg:flex-row odd:lg:flex-row-reverse lg:justify-center">
      <div className="flex-shrink-0 w-full max-w-3xl lg:flex-shrink">
        <img src={image} />
      </div>
      <div
        className={`flex flex-col justify-center relative -top-8 mx-4 p-8 text-white ${color} rounded-xl max-w-lg lg:top-0 xl:min-h-[360px] lg:mx-0 group-odd:lg:-mr-16 group-even:lg:-ml-16`}>
        <h3 className="text-2xl font-semibold mb-4">{title}</h3>
        <p className="text-lg">{text}</p>
      </div>
    </div>
  )
}

const InfoSection = () => {
  return (
    <div className="flex flex-col items-center py-16 lg:px-14">
      {infos.map((info, i) => (
        <Info key={i} {...info} />
      ))}
    </div>
  )
}

export default InfoSection
