import * as React from 'react'

import bullet_image from '../../images/bullet.svg'

const bullets = [
  'Most insurance plans accepted',
  'No license or certification required',
  'Available throughout NYS',
  'Direct deposit to caregiver',
  'Caregiver of your choice, including family or friends',
  'Same day pay',
  'Flexible spending account',
]

const BulletSection = () => {
  return (
    <div className="flex flex-col px-4 py-10 lg:px-14 sm:grid sm:grid-cols-2 sm:gap-x-4 max-w-6xl mx-auto">
      {bullets.map((b, i) => (
        <Bullet key={i} text={b} />
      ))}
    </div>
  )
}

const Bullet = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-row py-3">
      <div
        style={{
          backgroundImage: `url('${bullet_image}')`,
          backgroundSize: 'contain',
        }}
        className={'w-8 h-8 flex-shrink-0 flex-grow-0 mr-4'}></div>
      <div className="font-bold text-lg leading-7 pt-0.5">{text}</div>
    </div>
  )
}

export default BulletSection
