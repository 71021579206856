import React from 'react'

const Stars = ({ rating }) => {
  if (rating === null) return null
  return (
    <div className="flex flex-row gap-1 flex-shrink-0">
      {[...Array(rating)].map((_, i) => (
        <Star key={i} />
      ))}
    </div>
  )
}
const Star = ({ fill = '#D4C577' }) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.2889 7.3947L14.6938 6.43661L11.7455 0.462178C11.665 0.298601 11.5325 0.166182 11.3689 0.0856924C10.9585 -0.116831 10.4598 0.0519386 10.2546 0.462178L7.30634 6.43661L0.711168 7.3947C0.52934 7.42066 0.363097 7.50635 0.235817 7.63617C0.0819425 7.79426 -0.00284927 8.00694 7.31104e-05 8.22749C0.00299549 8.44803 0.0933929 8.6584 0.251402 8.81236L5.0231 13.4626L3.89576 20.029C3.86932 20.1818 3.88623 20.3389 3.94457 20.4825C4.00291 20.6261 4.10035 20.7505 4.22583 20.8416C4.35131 20.9327 4.49981 20.9869 4.6545 20.9979C4.80919 21.0089 4.96388 20.9764 5.10102 20.904L11.0001 17.8039L16.8991 20.904C17.0601 20.9897 17.2472 21.0183 17.4264 20.9871C17.8784 20.9092 18.1823 20.4808 18.1043 20.029L16.977 13.4626L21.7487 8.81236C21.8786 8.68513 21.9643 8.51896 21.9903 8.33721C22.0604 7.88283 21.7435 7.46221 21.2889 7.3947Z"
      fill={fill}
    />
  </svg>
)

export default Stars
