import React, { useMemo } from 'react'
import { getReviews } from './getReviews'
import Reviews from './Reviews'

const ReviewSection = () => {
  const [half_1, half_2] = useMemo(() => {
    const reviews = getReviews()
    const m = Math.floor(reviews.length / 2)
    return [reviews.slice(0, m), reviews.slice(m, reviews.length)]
  }, [])

  return (
    <div className="pt-16 pb-32">
      <h2 className="px-4 text-4xl text-center font-semibold">
        See what our clients have to say and learn more about what makes us the
        premier provider of home care services.
      </h2>
      <div className="mt-14">
        <Reviews reviews={half_1} reverse />
        <Reviews reviews={half_2} />
      </div>
    </div>
  )
}

export default ReviewSection
