import React, { useEffect, useMemo, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import { useSize } from '../../../utils/hooks'

import Stars from './Stars'

const Review = ({ text, author, rating, date }) => {
  return (
    <div className="bg-white rounded-lg p-8 max-w-xs flex-shrink-0 w-full max-h-60 flex flex-col">
      <h6
        className="text-xl font-semibold flex-shrink line-clamp-3"
        title={text}>
        {text}
      </h6>
      <div className="flex flex-row items-center mt-4">
        <Stars rating={rating} />
        <div className="ml-4 only:ml-0 text-grey-600 text-sm">{date}</div>
      </div>
      <div className="text-md flex-grow mt-4 text-lg">{author}</div>
    </div>
  )
}

const TIME_PER_REVIEW = 5000

const Reviews = ({ reverse = false, reviews }) => {
  const parentRef = useRef(null)
  const parentSize = useSize(parentRef)

  const [styles, api] = useSpring(() => ({
    x: 0,
    config: { duration: TIME_PER_REVIEW * reviews.length },
  }))

  const scrollSize = useMemo(() => {
    const nReviews = reviews.length
    return 320 * nReviews + 32 * (nReviews + 1) - (parentSize?.width ?? 0)
  }, [parentSize, reviews])

  useEffect(() => {
    api.start({
      reverse: reverse,
      delay: reverse ? 1000 : 2000,
      to: { x: 0 },
      loop: { reverse: true },
      from: { x: -scrollSize },
    })
  }, [scrollSize])

  const onMouseEnter = () => api.pause()
  const onMouseLeave = () => api.resume()

  return (
    <div
      ref={parentRef}
      className="w-full relative overflow-hidden"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <animated.div
        style={styles}
        className="flex flex-row gap-8 px-8 pb-8 animate-scroll">
        {reviews.map((review, i) => (
          <Review key={i} {...review} />
        ))}
      </animated.div>
    </div>
  )
}

export default Reviews
