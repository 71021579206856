import React from 'react'
import Form from './Form'
import GoogleReviewsBadge from './GoogleReviewsBadge'

const Hero = () => {
  return (
    <div className="flex flex-col lg:flex-row max-w-6xl mx-auto">
      <div className="flex-grow basis-1/2 pb-20 lg:pb-0 lg:pr-5 text-center lg:text-left">
        <h2 className="text-xl lg:text-4xl font-bold mb-3 tracking-tight">
          CDPAP Home Care
        </h2>
        <h1 className="text-4xl px-4 lg:px-0 lg:text-6xl lg:leading-snug font-bold">
          Get paid to care for a loved one
        </h1>
        <div className="font-bold text-sm mt-12 px-7 leading-6 lg:px-0 lg:font-normal lg:text-lg lg:my-8">
          <p className="mb-4">
            This NYS Medicaid funded program allows seniors and people with
            disabilities to select, train and direct their own caregivers,
            including family members and friends.
          </p>
          <p>
            If you or your loved one are in need of assistance with activities
            of daily living you may be eligible for CDPAP.
          </p>
        </div>
        <div className="hidden lg:block">
          <GoogleReviewsBadge />
        </div>
      </div>
      <div className="flex justify-center flex-grow basis-1/2">
        <div className="w-full border border-grey-200 rounded-2xl bg-white py-6 max-w-lg px-8 lg:bg-transparent lg:border-0">
          <Form />
        </div>
      </div>
    </div>
  )
}

export default Hero
