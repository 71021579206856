import React from 'react'

import program_1 from '../../../images/program_1.png'
import program_2 from '../../../images/program_2.png'
import program_3 from '../../../images/program_3.png'
import program_4 from '../../../images/program_4.png'

const programs = [
  {
    title: 'Home Care',
    image: program_1,
    text: 'Our skilled home aides will come to your house on time and ready to assist you or a family member with your activities of daily living.',
  },
  {
    title: 'CDPAP',
    image: program_2,
    text: 'Choose your own caregiver and have them get paid by us.',
  },
  {
    title: 'Patient client care',
    image: program_3,
    text: 'We ensure that setting up your long-term or short-term care will be a pleasant and speedy process.',
  },
  {
    title: 'Medicaid planning',
    image: program_4,
    text: 'We assist you in getting approved for Medicaid with our dedicated team.',
  },
]

const Program = ({ title, image, text }) => {
  return (
    <div className="border border-grey-600 bg-white rounded-xl overflow-hidden w-4/5 flex-shrink-0 mx-2 md:my-2 max-w-xs">
      <img src={image} alt={title} />
      <div className="py-6 px-8">
        <h5 className="text-2xl font-semibold mb-4">{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  )
}

const ProgramScroll = () => {
  return (
    <div className="p-4 flex flex-row overflow-x-auto md:flex-wrap md:justify-center md:px-10 md:mx-auto md:max-w-4xl 2xl:max-w-none">
      {programs.map((p, i) => (
        <Program key={i} {...p} />
      ))}
    </div>
  )
}

export default ProgramScroll
