import React from 'react'
import Button from '../Button'

const scrollToEligibilityForm = () => {
  document
    .querySelector('#eligibilityForm>h3')
    .scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const BottomCTASection = () => {
  return (
    <div className="px-6 py-20 md:px-12">
      <h2 className="text-4xl font-semibold text-center mb-14">
        Rest easy as we will take care of everything!
      </h2>
      <div className="flex flex-col items-center lg:flex-row lg:justify-center">
        <p className="font-bold text-center text-lg mb-6 lg:mb-0 lg:mr-12">
          <span className="inline-block">Call Us Today on</span>{' '}
          <a
            className="inline-block underline decoration-2 decoration-brand-200"
            href="tel:(516)4038211">
            (516) 403-8211
          </a>
        </p>
        <Button onClick={scrollToEligibilityForm}>Check eligibility now</Button>
      </div>
    </div>
  )
}

export default BottomCTASection
