import React from 'react'

import image1 from '../../../images/started_step_1.svg'
import image2 from '../../../images/started_step_2.svg'
import image3 from '../../../images/started_step_3.svg'
import ProgramScroll from './CommonPrograms'

const steps = [
  'Call and speak to one of our friendly team who will discuss the setup process.',
  'Have a nurse assessment to determine how many hours the Patient is eligible for. Most Home Care is covered through Medicaid.',
  'Once agreed, we will introduce you to your Care Worker, and get the care you need.',
]

const Step = ({ step, index }) => {
  return (
    <div
      className={`col-start-2 row-span-2 row-start-${
        index * 2 - 1
      } pb-14 lg:text-center lg:px-6 lg:row-start-2 lg:col-span-3 lg:col-start-auto lg:row-span-1`}>
      <div className="text-base lg:text-xl font-header mb-2">Step {index}</div>
      <div className="text-lg lg:text-2xl font-header">{step}</div>
    </div>
  )
}

// Dynamic classes used:
// this comments is needed to avoid purging these classes with the pre-processor
// const classes = "row-start-1 row-start-3 row-start-5"
const StepsTab = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto py-16 px-4 grid grid-rows-[repeat(3,80px_min-content)] grid-cols-[80px_minmax(0,_1fr)] gap-x-4 lg:grid-cols-[repeat(3,1fr_80px_1fr)] lg:grid-rows-[80px_minmax(0,_1fr)] lg:gap-y-5 lg:gap-x-0">
        <img
          className="col-start-1 lg:col-start-2 h-20 w-20"
          src={image1}
          alt="Step 1"
        />
        <div className="col-start-1 lg:col-start-3 lg:col-span-2 w-0.5 lg:h-0.5 lg:w-full lg:self-center justify-self-center bg-gradient-to-r from-[#D16BD3] to-[#714AA3]"></div>
        <img
          className="col-start-1 lg:col-start-5 h-20 w-20"
          src={image2}
          alt="Step 2"
        />
        <div className="col-start-1 lg:col-start-6 lg:col-span-2 w-0.5 lg:h-0.5 lg:w-full lg:self-center justify-self-center bg-gradient-to-r from-[#714AA3] to-[#E66675]"></div>
        <img
          className="col-start-1 lg:col-start-8 h-20 w-20"
          src={image3}
          alt="Step 3"
        />
        {steps.map((step, i) => (
          <Step key={i} index={i + 1} step={step} />
        ))}
      </div>
      <div>
        <ProgramScroll />
      </div>
    </>
  )
}

export default StepsTab
