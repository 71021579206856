import React from 'react'
import ProgramScroll from './CommonPrograms'

const FirstTab = () => {
  return (
    <>
      <h2 className="text-4xl leading-tight text-center font-semibold px-4 md:px-14 mb-14 max-w-7xl mx-auto">
        Not-for-Profit Home Care Agency Serving New York City and Nassau and
        Westchester Counties.
      </h2>
      <p className="text-lg text-center px-4 md:px-14 mb-10 max-w-7xl mx-auto">
        Rockaway Home Care provides the following home health care programs at
        flexible schedules and cost-friendly service rates.
      </p>
      <ProgramScroll />
    </>
  )
}

export default FirstTab
