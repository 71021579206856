import reviewFeed from './review_feed.json'

export const getReviews = () => {
  const items = reviewFeed?.rss?.channel?.item ?? []
  return items.map((item) => ({
    author: item.author || '',
    text: item.content || '',
    date: item.pubDate.slice(0, 10) || '',
    rating: parseFloat(item.title.slice(0, 2)) || null,
  }))
}
